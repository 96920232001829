export default ({ $config }) => {
  return [
    {
      value: "actions",
      text: "",
      width: 25,
      copyable: false,
    },
    {
      value: "name",
      text: "Name",
      visible: true,
      width: 200,
    },
    {
      value: "zohoId",
      text: "Zoho",
      sortable: false,
      visible: true,
      width: 40,
      align: "center",
      toStringFn: (item, key) =>
        item[key] && `${$config.public.zohoCrmUrl}/tab/Accounts/${item[key]}`,
    },
    {
      value: "linkedinUrl",
      text: "Linkedin",
      sortable: false,
      visible: false,
      width: 60,
    },
    {
      value: "exid",
      text: "Company ID",
      visible: true,
      width: 200,
    },
    {
      value: "domains",
      text: "Domains",
      visible: true,
      width: 150,
    },
    {
      value: "sessions",
      text: "# Sessions",
      visible: true,
      width: 80,
      align: "center",
    },
    {
      value: "users",
      text: "# Users",
      width: 80,
      align: "center",
    },
    {
      value: "activeUsers",
      text: "#WAU",
      width: 70,
      align: "center",
    },
    {
      value: "cameras",
      text: "# Cameras",
      width: 80,
      align: "center",
    },
    {
      value: "projects",
      text: "# Projects",
      width: 80,
      align: "center",
    },
    {
      value: "createdAt",
      text: "Created At",
      visible: true,
      width: 120,
    },
  ]
}
