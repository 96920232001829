export default (self) => {
  return {
    name: {
      component: "TextFieldSearchFilter",
    },
    exid: {
      component: "TextFieldSearchFilter",
    },
    sessions: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    users: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    domains: {
      component: "TextFieldSearchFilter",
    },
    activeUsers: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    cameras: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
    projects: {
      component: "CountFieldSearchFilter",
      listeners: {
        keypress: (e) =>
          self.allowKeypress(e, { comparison: true, number: true }),
      },
    },
  }
}
